<template>
  <div class="clinic-order-detail">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="order-detail-wrap">
          <div class="columns content-order-detail-wrap">
            <clinic-menu></clinic-menu>

            <div class="column is-12 right-column is-empty" v-if="order === null">
              <div class="columns">
                <div class="column is-8">
                  <h3 class="title is-4 pb-4 txt-c-mobile" v-if="isLoading">Searching por el pedido</h3>
                  <h3 class="title is-4 pb-4 txt-c-mobile" v-if="!isLoading">No pedido found</h3>
                </div>
                <div class="column is-4 has-text-right txt-c-mobile">
                  <router-link :to="'/clinic/orders'" class="is-underlined">Volver a todos los pedidos</router-link>
                </div>
              </div>
            </div>

            <div class="column is-12 right-column" v-if="order !== null">
              <div class="columns">
                <div class="column is-8">
                  <h3 class="title is-4 pb-4 txt-c-mobile">Pedido #{{order.order_number}} - {{order.created_at.split('T')[0]}}</h3>
                </div>
                <div class="column is-4 has-text-right txt-c-mobile">
                  <router-link :to="'/clinic/orders'" class="is-underlined">Volver a todos los pedidos</router-link>
                </div>
              </div>

              <div class="columns mb-0">
                <div class="column is-6 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Estado
                  </h3>
                </div>
                <div class="column is-6 mb-0 has-text-right">
                  <!-- Created -->
                  <h3 class="order-status red mb-0" v-if="order.status === 'created'">
                    To be approved <span v-if="order.status === 'under_review'">(under review)</span>
                  </h3>
                  <!-- Started -->
                  <h3 class="order-status yellow mb-0" v-if="order.status === 'started'">
                    El pedido se está produciendo
                  </h3>
                  <!-- Processed -->
                  <h3 class="order-status yellow mb-0" v-if="order.status === 'processed'">
                    El pedido está listo para el envío
                  </h3>
                  <button class="icon-button mt-0" v-if="order.status === 'processed'">
                    <span class="icon icon-download"></span> <span class="btn-label">Descarga las etiquetas de envío y las instrucciones</span>
                  </button>
                  <!-- Shipped -->
                  <h3 class="order-status green mb-0" v-if="order.status === 'shipped'">
                    Pedido enviado
                  </h3>
                  <!-- Delivered -->
                  <h3 class="order-status green mb-0" v-if="order.status === 'delivered'">
                    Pedido DELIVERED
                  </h3>
                  <!-- Canceled -->
                  <h3 class="order-status red mb-0" v-if="order.status === 'cancelled'">
                    Pedido CANCELED
                  </h3>
                  <!-- Archived -->
                  <h3 class="order-status mb-0" v-if="order.status === 'archived'">
                    Pedido Archived
                  </h3>
                </div>
              </div>

              <hr>

              <div class="columns mb-0 is-flex-wrap-wrap">
                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Laboratorio
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.laboratory_name}}</span><br>
                    {{orderItem.laboratory_address_city}}, {{orderItem.laboratory_address_state}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Producto
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.product_name}}</span><br>
                    CANTITAD {{orderItem.quantity}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Paziente
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.first_name}} {{orderItem.last_name}}</span><br>
                    NUMERO EXPEDIENTE: {{(orderItem.file_number == null) ? 'no numero' : orderItem.file_number}}
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Fecha de entrega deseada
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">XX/XX/XXXX</span><br>
                  </p>
                  <p class="has-text-danger">
                    Last modified: <span class="has-text-weight-bold">X days ago</span> by <span class="has-text-weight-bold">XXXXX</span>
                  </p>
                </div>

                <div class="column is-3 mb-0">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Precio
                  </h3>
                </div>
                <div class="column is-9 mb-0">
                  <p>
                    <span class="has-text-weight-bold">{{orderItem.total_price / 100}} €</span>
                  </p>
                </div>
              </div>

              <div class="columns mb-3">
                <div class="column is-12">
                  <complementary-list :product-offer-id="orderItem.product_offer_id" :show-extras="extras.length > 0" :active-extras="extras"></complementary-list>
                </div>
              </div>

              <div class="columns mb-3">
                <div class="column is-8">
                  <div class="notification has-background-light txt-14 mt-4">
                    <span v-if="orderItem.status === 'pending'">Detalles añadidos correctamente</span>
                    <span class="has-text-danger" v-if="orderItem.status === 'under_review'">Faltan detalles en esta orden de trabajo.</span>
                    <span class="has-text-grey-lighter" v-if="orderItem.status !== 'under_review' && orderItem.status !== 'pending'">No se puede editar el archivo ahora</span>
                  </div>
                </div>
                <div class="column is-4 has-text-right">
                  <!-- TODO: Button disabled based on status -->
                  <button class="button is-primary is-outlined mt-5" :class="{'is-primary': orderItem.status !== 'under_review', 'is-danger': orderItem.status === 'under_review'}" :disabled="orderItem.status !== 'under_review' && orderItem.status !== 'created'" @click="editOrder(orderItem)">
                    <span v-if="orderItem.status !== 'under_review'">Editar</span>
                    <span v-if="orderItem.status === 'under_review'">Revisar</span>
                  </button>
                </div>
              </div>

              <hr>

              <div class="columns mb-3 communication-center is-flex-wrap-wrap">
                <div class="column is-12">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    communication center
                  </h3>
                </div>
                <div class="column is-12">
                  <communication-center v-if="!isLoading && order.status !== 'archived' && order.status !== 'cancelled'" :order-id="order.id" :name="clinic.company_name"></communication-center>
                </div>
              </div>

              <hr>

              <!-- v-if="order.status === 'created' || order.status === 'shipped_to_clinic'" -->
              <!-- response[i].order_field.values.includes('physical') -->
              <div class="columns mb-3 is-flex-wrap-wrap">
                <!-- UPLOADED STL -->
                <div class="column is-12 upload-wrap">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    STL/DWG files
                  </h3>
                  <small>Format: stl, dwg (max 10MB)</small>
                  <div class="columns is-flex-wrap-wrap upload-file-list">
                    <div class="column is-6" v-for="(file, index) in uploadedStlFiles" v-bind:key="file.id">
                      <div class="upload-element has-background-light">
                        <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                        <button class="remove-upload-btn" @click="removeFile(uploadedStlFiles, index)"><span class="icon-close"></span></button>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-flex-wrap-wrap upload-file-list loading" v-if="filesStl.length > 0">
                    <div class="column is-6" v-for="(file, index) in filesStl" v-bind:key="index">
                      <div class="upload-element has-background-light">
                        <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                        <span class="progress-label">{{parseInt(file.progress)}}%</span>
                        <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
                      </div>
                    </div>
                  </div>

                  <!-- TO CHECK :disabled="order.status !== 'created' && order.status !== 'shipped_to_clinic'" -->
                  <file-upload
                    input-id="fileStdDetail"
                    class="upload-btn"
                    :headers="headers"
                    :post-action="uploadPostAction"
                    :multiple="true"
                    extensions="stl,dwg"
                    :size="1024 * 1024 * 10"
                    v-model="filesStl"
                    name="uploaded_file"
                    :thread="1"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    :data="{ upload_type: 'stl' }"
                    ref="uploadStlDetails">
                    Upload new file <span class="icon icon-upload"></span>
                  </file-upload>
                  <hr class="light">
                </div>
                <!-- UPLOAD IMAGES -->
                <div class="column is-12 upload-wrap">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    Images
                  </h3>
                  <small>Format: png, jpg, jpeg, webp, bmp (max 10MB)</small>
                  <!-- UPLOADED FILES -->
                  <div class="columns is-flex-wrap-wrap upload-file-list" v-if="uploadedImgFiles.length > 0">
                    <div class="column is-6" v-for="(file, index) in uploadedImgFiles" v-bind:key="file.id">
                      <div class="upload-element has-background-light">
                        <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                        <button class="remove-upload-btn" @click="removeFile(uploadedImgFiles, index)"><span class="icon-close"></span></button>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-flex-wrap-wrap upload-file-list loading" v-if="filesImg.length > 0">
                    <div class="column is-6" v-for="(file, index) in filesImg" v-bind:key="index">
                      <div class="upload-element has-background-light">
                        <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                        <span class="progress-label">{{parseInt(file.progress)}}%</span>
                        <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
                      </div>
                    </div>
                  </div>

                  <file-upload
                    input-id="fileImgDetail"
                    class="upload-btn"
                    :headers="headers"
                    :post-action="uploadPostAction"
                    :multiple="true"
                    extensions="png,jpg,jpeg,webp,bmp"
                    :size="1024 * 1024 * 10"
                    v-model="filesImg"
                    name="uploaded_file"
                    :thread="1"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    :data="{ upload_type: 'image' }"
                    ref="uploadImgDetails">
                    Upload new file <span class="icon icon-upload"></span>
                  </file-upload>
                  <hr class="light">
                </div>
                <!-- UPLOADED DCOM -->
                <div class="column is-12 upload-wrap">
                  <h3 class="has-text-weight-bold txt-yellow is-uppercase">
                    DCOM files
                  </h3>
                  <small>Format: dcm (max 10MB)</small>
                  <div class="columns is-flex-wrap-wrap upload-file-list" v-if="uploadedDcomFiles.length > 0">
                    <div class="column is-6" v-for="(file, index) in uploadedDcomFiles" v-bind:key="file.id">
                      <div class="upload-element has-background-light">
                        <p>{{file.filename.substring(0, 28)}} ({{convertSize(file.byte_size)}})</p>
                        <button class="remove-upload-btn" @click="removeFile(uploadedDcomFiles, index)"><span class="icon-close"></span></button>
                      </div>
                    </div>
                  </div>

                  <div class="columns is-flex-wrap-wrap upload-file-list" v-if="filesDcom.length > 0">
                    <div class="column is-6" v-for="(file, index) in filesDcom" v-bind:key="index">
                      <div class="upload-element has-background-light">
                        <p>{{file.name.substring(0, 28)}} ({{convertSize(file.size)}})</p>
                        <span class="progress-label">{{parseInt(file.progress)}}%</span>
                        <div class="loading-bar" :style="{width: file.progress + '%'}"></div>
                      </div>
                    </div>
                  </div>

                  <!-- accept="image/png,image/gif,image/jpeg,image/webp" -->
                  <file-upload
                    input-id="fileDcomDetail"
                    class="upload-btn"
                    :headers="headers"
                    :post-action="uploadPostAction"
                    :multiple="true"
                    extensions="dcm,lzw,jpg"
                    :size="1024 * 1024 * 10"
                    v-model="filesDcom"
                    name="uploaded_file"
                    :thread="1"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    :data="{ upload_type: 'dicom' }"
                    ref="uploadDcomDetails">
                    Upload new file <span class="icon icon-upload"></span>
                  </file-upload>
                </div>
              </div>

              <hr>

              <div class="columns mb-3 is-flex-wrap-wrap">
                <div class="column is-12">
                  <file-upload-stlab :title-uploader="'Attachments (e.g. Certificates, Documents...)'" :type="'pdf'" :file-supported="'pdf'" :destination-id="orderItem.id" :is-laboratory="false" :uploaded-files="orderItem.uploads"></file-upload-stlab>
                </div>
              </div>

              <hr>

              <div class="columns is-variable is-3 is-flex-wrap-wrap">
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box">
                    <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de envío</strong></p>
                    <p>
                      <strong>{{order.shipping_address.name}}</strong><br>
                      {{order.shipping_address.address1}}<br>
                      {{order.shipping_address.zip}} {{order.shipping_address.city}} ({{order.shipping_address.province}}), {{order.shipping_address.country}}<br>
                      {{order.shipping_address.phone}}<br><br>
                      <strong>Envío ordinario</strong>
                    </p>
                  </div>
                </div>
                <div class="column mb-1 is-6">
                  <div class="has-background-light recap-order-box">
                    <p class="pb-4"><strong class="txt-yellow is-uppercase">Dirección de facturación</strong></p>
                    <p>
                      <strong>{{order.billing_address.name}}</strong><br>
                      {{order.billing_address.address1}}<br>
                      {{order.billing_address.zip}} {{order.billing_address.city}} ({{order.billing_address.province}}), {{order.billing_address.country}}<br>
                      {{order.billing_address.phone}}<br><br>&nbsp;
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box">
                    <p class="pb-4"><strong class="txt-yellow is-uppercase">Método de pago</strong></p>
                    <p>
                      <strong>TODO Mastercard que termina con 4899</strong><br>
                      Cad. 9/24<br>
                      John Doe
                    </p>
                  </div>
                </div>
                <div class="column is-6 mb-1">
                  <div class="has-background-light recap-order-box">
                    <div class="columns is-mobile">
                      <div class="column is-8">
                        <p><strong class="txt-yellow is-uppercase">SUBTOTAL</strong></p>
                      </div>
                      <div class="column is-4 has-text-right">{{order.total_price / 100}}€</div>
                    </div>
                    <div class="columns is-mobile">
                      <div class="column is-8">
                        <p><strong class="txt-yellow is-uppercase">Coste de envío estimado</strong></p>
                      </div>
                      <div class="column is-4 has-text-right">
                        <p v-if="shipmentCost === 0">GRATIS</p>
                        <p v-if="shipmentCost !== 0">{{shipmentCost}}€</p>
                      </div>
                    </div>
                    <!-- <template v-for="lab in recapLabs">
                      <div class="columns is-mobile" v-bind:key="lab.id">
                        <div class="column is-8">
                          <p>{{lab.laboratory_name}}</p>
                        </div>
                        <div class="column is-4 has-text-right">
                          <p v-if="lab.shipmentCosts === 0">GRATIS</p>
                          <p v-if="lab.shipmentCosts !== 0">{{lab.shipmentCosts}}€</p>
                        </div>
                      </div>
                    </template> -->

                    <div class="columns is-mobile">
                      <div class="column is-8">
                        <p><strong class="txt-yellow is-uppercase">Código promocional</strong></p>
                      </div>
                      <div class="column is-4 has-text-right">-</div>
                    </div>
                    <div class="columns is-mobile">
                      <div class="column is-8">
                        <p><strong class="txt-yellow is-uppercase">TOTAL</strong></p>
                      </div>
                      <div class="column is-4 has-text-right">{{order.total_price / 100}}€</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns mt-4 mb-3" v-if="order.status === 'created'">
                <div class="column is-7">
                  <p>
                    NOTA - Aprobar este pedido cambiará su estado a "EN PROCESO". El paso siguiente es la fabricación de la pieza por parte del laboratorio
                  </p>
                  <p>
                    <a class="is-underlined" href="#">Contactar con nosotros</a><br>
                    <a class="is-underlined" href="#">Ver la sección de ayuda</a><br>
                    <a class="is-underlined" href="#">Ver la sección de ayuda</a>
                  </p>
                </div>
                <!-- <div class="column is-5">
                  <button class="button is-primary is-fullwidth is-medium" @click="setOrderToStarted()">Aprobar el pedido</button>
                  <p class="has-text-centered mt-4">
                    <a class="is-underlined" href="#" @click="setOrderToCancel()">Rechazar el pedido</a>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import FileUploadStlab from '@/components/FileUploadStlab.vue'
import ClinicMenu from '@/components/Clinic/ClinicMenu.vue'
import HeroImage from '@/components/HeroImage.vue'
import CommunicationCenter from '@/components/CommunicationCenter.vue'
import Footer from '@/components/Footer.vue'
import ComplementaryList from '@/components/ComplementaryList.vue'
import { mapGetters } from 'vuex'
import {
  HumanFileSize,
  IsEmptyOrNull
  // IsEmptyOrNull,
  // AddClassById,
  // RemoveClassBySelector,
  // ScrollTo,
  // ElemOffset,
  // RemoveClassById
} from '@/utils/utils'
export default {
  name: 'ClinicOrderDetail',
  components: {
    ClinicMenu,
    HeroImage,
    Footer,
    ComplementaryList,
    FileUploadStlab,
    CommunicationCenter
  },
  data () {
    return {
      uploadPostAction: 'https://api-staging.stlab.es/api/v1/uploads',
      isLoading: true,
      shippingLocation: null,
      billingLocation: null,
      pickupLocation: null,
      order: null,
      orderItem: null,
      recapLabs: [],
      shipmentCost: 0,
      productsBylab: [],
      uploadedStlFiles: [],
      uploadedImgFiles: [],
      uploadedDcomFiles: [],
      filesStl: [],
      filesImg: [],
      filesDcom: [],
      headers: null,
      extras: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      clinic: 'user/clinic',
      locations: 'clinic/locations'
    })
  },
  methods: {
    addUploadedFiles () {
      if ((this.uploadedStlFiles.length + this.uploadedImgFiles.length + this.uploadedDcomFiles.length) > 0) {
        let i = 0
        for (i = 0; i < this.uploadedStlFiles.length; i++) {
          this.orderItem.upload_ids.push(this.uploadedStlFiles[i].id)
        }
        for (i = 0; i < this.uploadedImgFiles.length; i++) {
          this.orderItem.upload_ids.push(this.uploadedImgFiles[i].id)
        }
        for (i = 0; i < this.uploadedDcomFiles.length; i++) {
          this.orderItem.upload_ids.push(this.uploadedDcomFiles[i].id)
        }
      }
    },
    initUploader () {
      this.uploadPostAction = process.env.VUE_APP_BASE_URL + 'clinics/' + this.clinic.id + '/uploads'
      const accessToken = localStorage.getItem('token')
      this.headers = {
        Authorization: `Bearer ${accessToken}`
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)
      }
      if (newFile && oldFile) {
        // update --> check errors
        console.log('update', newFile)
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (!IsEmptyOrNull(newFile.error)) {
          if (newFile.error === 'extension') {
            let i = 0
            for (i = 0; i < this.filesStl.length; i++) {
              if (newFile.id === this.filesStl[i].id) {
                this.filesStl.splice(i, 1)
                break
              }
            }
            for (i = 0; i < this.filesImg.length; i++) {
              if (newFile.id === this.filesImg[i].id) {
                this.filesImg.splice(i, 1)
                break
              }
            }
            for (i = 0; i < this.filesDcom.length; i++) {
              if (newFile.id === this.filesDcom[i].id) {
                this.filesDcom.splice(i, 1)
                break
              }
            }
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: 'File extension not supported'
              }
            })
          }
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!IsEmptyOrNull(newFile)) {
          switch (newFile.data.upload_type) {
            case 'stl':
              if (!this.$refs.uploadStlDetails.active) {
                this.$refs.uploadStlDetails.active = true
              }
              break
            case 'image':
              if (!this.$refs.uploadImgDetails.active) {
                this.$refs.uploadImgDetails.active = true
              }
              break
            case 'dicom':
              if (!this.$refs.uploadDcomDetails.active) {
                this.$refs.uploadDcomDetails.active = true
              }
              break
            default:
          }
        }
      }

      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        if (newFile.xhr) {
          //  Get the response status code
          if (newFile.xhr.status !== 200) {
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: newFile.error
              }
            })
            switch (newFile.data.upload_type) {
              case 'stl':
                this.$refs.uploadStlDetails.remove(newFile)
                break
              case 'image':
                this.$refs.uploadImgDetails.remove(newFile)
                break
              case 'dicom':
                this.$refs.uploadDcomDetails.remove(newFile)
                break
              default:
            }
          } else {
            console.log('FINISHED!')
            console.log(newFile.data.upload_type)
            switch (newFile.data.upload_type) {
              case 'stl':
                this.uploadedStlFiles.push(newFile.response)
                this.$refs.uploadStlDetails.remove(newFile)
                break
              case 'image':
                this.uploadedImgFiles.push(newFile.response)
                this.$refs.uploadImgDetails.remove(newFile)
                break
              case 'dicom':
                this.uploadedDcomFiles.push(newFile.response)
                this.$refs.uploadDcomDetails.remove(newFile)
                break
              default:
            }
            this.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'success',
                message: 'File uploaded successfully!'
              }
            })
          }
        }
      }
    },
    convertSize (size) {
      return HumanFileSize(size)
    },
    removeFile (fileArray, index) {
      fileArray.splice(index, 1)
    },
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    editOrder (product) {
      const lab = {
        name: product.laboratory_name,
        address_city: product.laboratory_address_city,
        address_state: product.laboratory_address_state
      }

      const modalOptions = {
        componentId: 'CompleteOrderDetails',
        title: 'Revisar los detalles de la orden',
        laboratory: lab,
        orderId: this.order.id,
        productId: product.product_id,
        productName: product.product_name,
        productOfferId: product.product_offer_id,
        productOfferPrice: product.unit_price,
        patientName: product.first_name,
        patientLastName: product.last_name,
        odontologistName: product.odontologist_name,
        shoppingCartItem: product,
        smallSize: false,
        isUpdate: true,
        isOrder: true,
        isReview: product.status === 'under_review',
        readOnly: false,
        updateCb: this.fetchOrder
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    initProductsList () {
      this.extras = []
      let i = 0
      for (i = 0; i < this.order.order_items.length; i++) {
        if (this.order.order_items[i].extra) {
          this.extras.push(this.order.order_items[i])
        }
      }
    },
    initOrderItem () {
      let i = 0
      for (i = 0; i < this.order.order_items.length; i++) {
        if (!this.order.order_items[i].complementary) {
          this.orderItem = this.order.order_items[i]
          break
        }
      }
    },
    fetchOrder () {
      const self = this
      this.isLoading = true
      this.$store.dispatch('clinic/getOrder', {
        orderId: this.$route.params.orderId,
        cb: (data) => {
          console.log('ORDER:')
          console.log(data)
          self.order = data
          self.initOrderItem()
          self.initProductsList()
          self.fetchLocations()
          self.initRecapLabs()
          self.initUploads()
          self.isLoading = false
        },
        cbError: () => {
          self.isLoading = false
          const errorMessage = '¡Error! Can\'t fetch order number ' + self.$route.params.orderId
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: errorMessage
            }
          })
        }
      })
    },
    initRecapLabs () {
      const self = this
      for (let i = 0; i < this.order.order_items.length; i++) {
        const isFound = this.recapLabs.some(element => {
          if (element.id === self.order.order_items[i].laboratory_id) {
            return true
          }

          return false
        })

        if (!isFound) {
          this.shipmentCost += 0
          this.recapLabs.push({
            id: this.order.order_items[i].laboratory_id,
            laboratory_name: this.order.order_items[i].laboratory_name,
            shipmentCosts: 0
          })
        }
      }
    },
    initUploads () {
      for (let i = 0; i < this.orderItem.uploads.length; i++) {
        switch (this.orderItem.uploads[i].upload_type) {
          case 'stl':
            this.uploadedStlFiles.push(this.orderItem.uploads[i])
            break
          case 'image':
            this.uploadedImgFiles.push(this.orderItem.uploads[i])
            break
          case 'dicom':
            this.uploadedDcomFiles.push(this.orderItem.uploads[i])
            break
          default:
        }
      }
    },
    setLocations () {
      for (let i = 0; i < this.locations.length; i++) {
        if (this.order.shipping_location_id === this.locations[i].id) {
          this.shippingLocation = this.locations[i]
        }
        if (this.order.pickup_location_id === this.locations[i].id) {
          this.pickupLocation = this.locations[i]
        }
        if (this.order.billing_location_id === this.locations[i].id) {
          this.billingLocation = this.locations[i]
        }
      }
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('clinic/getLocations', {
        clinicId: this.clinic.id,
        cb: (data) => {
          self.setLocations()
        },
        cbError: () => {
          const errorMessage = '¡Error! Can\'t get locations'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: errorMessage
            }
          })
        }
      })
    }
  },
  created () {
    this.initUploader()
    this.fetchOrder()
  }
}
</script>

<style scoped lang="scss">
.clinic-order-detail{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .order-detail-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-order-detail-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          .recap-order-box{
            min-height: 275px;
            border-radius: 8px;
            padding: 21px 28px;
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
          .order-status{
            position: relative;
            padding-right: 40px;
            margin-bottom: 21px;
            &:before{
              position: absolute;
              right: 0px;
              top: -2px;
              content: "";
              height: 23px;
              width: 23px;
              border-radius: 50%;
              background-color: #57567C;
            }
            &.red{
              &:before{
                background-color: #E37878;
              }
            }
            &.yellow{
              &:before{
                background-color: #FFD514;
              }
            }
            &.green{
              &:before{
                background-color: #94C38E;
              }
            }
          }

          .upload-wrap{
            position: relative;
            .upload-btn{
              background-color: transparent;
              border: 0px;
              position: absolute;
              top: 0px;
              right: 0px;
              text-decoration: underline;
              cursor: pointer;
              font-size: 1.1rem;
              color: #57567C;
              z-index: 999;
              margin-right: 8px;
              span{
                position: relative;
                top: 4px;
                font-size: 2rem;
                margin-left: 14px;
                color: #57567C;
              }
              #fileStdDetail{
                &:disabled{
                  opacity: .5;
                }
              }
            }

            .upload-file-list{
              margin-top: 14px;
              .upload-element{
                position: relative;
                padding: 14px;
                border-radius: 4px;
                p{
                  display: block;
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-weight: bold;
                }
                .remove-upload-btn{
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  width: calc(10% + 10px);
                  height: 100%;
                  background-color: transparent;
                  border: 0px;
                  color: #57567C;
                  cursor: pointer;
                }
                .progress-label{
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  width: calc(10% + 10px);
                  height: 100%;
                  background-color: transparent;
                  border: 0px;
                  color: #57567C;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .loading-bar{
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  width: 0px;
                  height: 2px;
                  background-color: #57567C;
                  transition: width .3s ease-out;
                }
              }
            }
          }

          .location-box, .total-box{
            border-radius: 8px;
            padding: 21px 28px;
          }
        }
      }
    }
  }
}
</style>
